<script setup lang="ts">
useHead({
  link: [
    {
      rel: "stylesheet",
      href: "/assets/compile/tailwind.css",
    },
  ],
});
</script>
<template>
  <div
    class="tw-min-h-[calc(var(--vh)-var(--om-floating-height))] tw-flex tw-flex-col tw-bg-nl-yellow-100 tw-text-nl-green-100 tw-antialiased tw-text-body-sm md:tw-text-body"
  >
    <main id="content" class="tw-flex-1 tw-flex tw-flex-col">
      <slot/>
    </main>
  </div>
</template>

<style scoped></style>
